import { useCallback, useMemo } from 'react';
import { ApolloError } from 'apollo-client';
import { useRouter } from 'next/router';

import { PersonalizedProductsQuery } from 'src/personalization/data-layer/types';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import {
  ItemListName,
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import useUI from 'src/hooks/use-ui';
import { tracker } from 'src/utils/analytics';
import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';

import { Product, parseProduct } from 'src/utils/helpers/product';

type UsePersonalizedProductsParams = {
  personalizedProductsQuery: PersonalizedProductsQuery;
};

export type UsePersonalizedProductsReturn = {
  error?: ApolloError;
  loading: boolean;
  products: Product[];
  handleProductClick: (product: Product, index: number, trackerSourceOverride: ItemListName) => void;
  handleQuickAddToCart: (product: Product, index: number, trackerSourceOverride: ItemListName) => void;
};

export const usePersonalizedProducts = ({
  personalizedProductsQuery,
}: UsePersonalizedProductsParams): UsePersonalizedProductsReturn => {
  const UI = useUI();
  const router = useRouter();
  const { handleAddToCart } = useQuickAddToCart();
  const { data, loading, error } = personalizedProductsQuery;

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: ProductRecommendationSection.personalizedRecommendations,
  });

  const products = useMemo(
    () => data?.getPersonalizedProducts?.products.map((product): Product => parseProduct(product)) ?? [],
    [data?.getPersonalizedProducts?.products]
  );

  const handleProductClick = useCallback(
    (product, index, trackerSourceOverride): void => {
      const newTrackerSource = trackerSourceOverride ?? trackerSource;

      tracker.setContext({
        activeProductPosition: index,
      });

      tracker.productClicked({ product, position: index, trackerSource: newTrackerSource });

      UI.activeProductSource = newTrackerSource;
    },
    [UI, trackerSource]
  );

  const handleQuickAddToCart = useCallback(
    (product, index, trackerSourceOverride): void => {
      const newTrackerSource = trackerSourceOverride ?? trackerSource;

      tracker.setContext({
        activeProductPosition: index,
      });
      void handleAddToCart(product, newTrackerSource);
    },
    [handleAddToCart, trackerSource]
  );

  return {
    products,
    loading,
    error,
    handleProductClick,
    handleQuickAddToCart,
  };
};
